import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Attorney = ({ name, photo }) => (
  <div
    className="my-2 justify-content-center d-flex flex-column"
    style={{
      background:
        "radial-gradient(circle, rgba(1,133,110,.2) 40%, rgba(0,0,0,0) 60.3%)",
    }}
  >
    <img src={photo} className="img-fluid" alt={name} />
    <div className="scaleUp bg-light">
      <div className="py-3 text-center shadow-sm">
        <div>
          <Link
            to={`/attorneys/${name.replace(" ", "-").toLowerCase()}`}
            className="stretched-link text-dark font-weight-bold"
            style={{ fontSize: "1.2rem" }}
          >
            {name}
          </Link>
        </div>
        <div className="text-muted">Partner</div>
      </div>
    </div>
  </div>
)

Attorney.propTypes = {
  siteTitle: PropTypes.string,
}

Attorney.defaultProps = {
  siteTitle: ``,
  bg: `light`,
}

export default Attorney
