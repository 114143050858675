import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { Container, ResponsiveEmbed, Row, Col } from "react-bootstrap"
import Footer from "../components/footer"

export default () => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="About RWK Attorneys" defer={false} />

    <div style={{ height: "350px" }} className="w-100 bg-dark">
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col xl="8">
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-0"
              style={{ fontWeight: 600 }}
            >
              Established 2000
            </p>
            <h1 className="display-3">About RWK</h1>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row>
        <Col xl="7">
          <h1 className="mt-3">RWK</h1>
          <p className="lead">
            Serving Jefferson, Shelby, Blount and St. Clair counties for nearly
            20 years.
          </p>
          <p>
            We take great pride in assisting our clients and that is why we
            choose to put forth the most aggressive representation for each of
            them. When you hire our firm, you’re hiring the skill and experience
            in all of the attorneys in our firm. Effective attorneys don’t just
            know the law; they understand it. That is why we work as a team to
            obtain the best possible results for you. Regardless of your
            particular legal needs, Russo, White &amp; Keller, P.C. can provide
            you with the effective legal assistance to get you moving forward in
            the right direction.
          </p>

          <p>
            We love to meet new clients and learn more about their legal needs.
            If you have questions or are not sure how to proceed in your
            situation, give us a call. The call is completely free. We will
            determine who you can talk to and we will schedule time that is
            convenient for you to talk with an attorney that is qualified to
            help you. Call us now and let the attorneys of Russo, White &amp;
            Keller, P.C. start helping you immediately!
          </p>
        </Col>
        <Col className="mt-xl-5">
          <ResponsiveEmbed aspectRatio="16by9">
            <iframe
              title="commercial"
              className="embed-responsive-item"
              src="https://player.vimeo.com/video/274712493"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbed>
        </Col>
      </Row>
    </Container>

    <Footer />
  </Layout>
)
