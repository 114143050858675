import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import PracticeAreaSideBar from "../../../components/practiceAreaSideBar"
import Footer from "../../../components/footer"

const PersonalInjuryPage = ({ data }) => (
  <Layout>
    <SEO
      title="Criminal Law | Practice Areas"
      description="Regardless of whether you were arrested for a crime against a person (like assault and battery, rape, or murder), a crime against property (like shoplifting, burglary, or arson), or a drug crime (marijuana possession or cocaine dealing), we can help."
    />

    <div className="bg-dark" style={{ height: "350px" }}>
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col>
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-2"
              style={{ fontWeight: 600 }}
            >
              Relief for injuries
            </p>

            <h1 className=" display-3">Personal Injury Law</h1>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row>
        <Col
          xl="8"
          className="py-3 border-left border-right bg-light shadow-sm"
        >
          <Container>
            <Row>
              <Col>
                <h2>Helping Injury Victims Get Justice In Alabama</h2>
                <p>
                  We are dedicated to protecting the rights and interests of
                  those who have been hurt due to the negligence or recklessness
                  of another party. We provide free, no-obligation
                  consultations. Please call our Birmingham personal injury
                  attorneys or fill out our online contact form today.
                </p>

                <p>
                  It's natural to be concerned about how you’ll pay for the cost
                  of treatment and recoup wages that you’ve lost, among other
                  expenses you’ve likely incurred if you have been hurt due to
                  someone else’s recklessness or negligence in Alabama. The
                  purpose of personal injury law is to return injured victims to
                  the position they were in prior to the unfortunate accident.
                  We know that in practice, this is difficult and nearly
                  impossible, but using the legal system and with the right
                  personal injury lawyers on your side, we can help you get as
                  close as possible. Every case is different, but we strive to
                  ensure justice prevails.
                </p>

                <p>We handle many types of personal injury cases such as:</p>
                <ul>
                  <li>Automobile Accidents</li>
                  <li>Defective Products</li>
                  <li>Drug Injuries</li>
                  <li>Semi-trailer Truck Accidents</li>
                  <li>Wrongful Death</li>
                </ul>

                <p>
                  If you have been injured in an accident or have been injured
                  due to a defective product or from taking certain drugs, we
                  would like to speak with you to see if we can assist you.
                  Contact us today for a free consultation.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
        <PracticeAreaSideBar contactMessage="Contact us today if you have been injured to receive free legal consultation." />
      </Row>
    </Container>

    <Footer />
  </Layout>
)

export default PersonalInjuryPage
