import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Col, Container, Card, Accordion, Button } from "react-bootstrap"
import PracticeAreaSideBar from "../../../components/practiceAreaSideBar"
import Footer from "../../../components/footer"
import BackgroundImage from "gatsby-background-image"

const FamilyPage = ({ data }) => (
  <Layout>
    <SEO
      title="Family Law | Practice Areas"
      description="Regardless of whether you were arrested for a crime against a person (like assault and battery, rape, or murder), a crime against property (like shoplifting, burglary, or arson), or a drug crime (marijuana possession or cocaine dealing), we can help."
    />

    <BackgroundImage
      // className="vh-100"
      fluid={[
        data.cover_xs.childImageSharp.fluid,
        {
          ...data.cover_sm.childImageSharp.fluid,
          media: `(min-width: 576px)`,
        },
        {
          ...data.cover_md.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
        {
          ...data.cover_lg.childImageSharp.fluid,
          media: `(min-width: 992px)`,
        },
        {
          ...data.cover_xl.childImageSharp.fixed,
          media: `(min-width: 1200px)`,
        },
      ]}
      style={{
        // backgroundSize: "",
        backgroundPosition: "top",
        height: "350px",
      }}
    >
      {/* <div className="bg-dark" style={{ height: "350px" }}> */}
      <Container className="h-100">
        <Row className="align-items-center h-100">
          <Col>
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-2"
              style={{ fontWeight: 600 }}
            >
              1300+ Cases Filed
            </p>
            <h1 className=" display-3">Family Law</h1>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </BackgroundImage>

    <Container>
      <Row>
        <Col
          xl="8"
          className="py-3 border-left border-right bg-light shadow-sm"
        >
          <Container>
            <Row>
              <Col>
                <h1 className="mt-3 mt-sm-0 text-center text-sm-left">
                  Family Law
                </h1>
                <p>
                  Our firm will help you through legal matters involving family
                  relationships, such as adoption, divorce, child support and
                  many others. We represent clients in family court proceedings
                  or in related negotiations. We also draft important legal
                  documents such as court petitions and property agreements. We
                  can help you in all family law matters.
                </p>

                <h2>Divorce</h2>
                <p>
                  We understand that divorce is never an easy decision. It is
                  important to have attorneys who not only understand your
                  situation, but who also will fight to get the results you
                  deserve. We have over 30 years of combined experience and will
                  work hard to provide you with the best results possible.
                </p>
                <p>
                  Divorce is difficult enough. Making a decision about hiring an
                  Alabama divorce attorney should not add to this stress. During
                  this difficult time, you need a divorce attorney who is
                  understanding and compassionate, yet able to fight for your
                  rights. The attorneys at Russo, White &amp; Keller have
                  practiced divorce law on a daily basis for well over 25 years.
                  We will fight for your rights and what you deserve.
                </p>

                <p>
                  We are more than qualified to assist you in preparing a
                  comprehensive settlement agreement and obtaining an
                  uncontested divorce. We can streamline the process and prepare
                  the necessary paperwork so that you don’t even go to court.
                </p>
                <h3>Uncontested</h3>
                <p>Have an overall consensus on how the marriage should end?</p>

                <p>
                  When it comes to divorce cases, the devil is in the details.
                  Both parties may have an overall consensus on how the marriage
                  should end, but putting that in a legally binding agreement is
                  not as straight forward.
                </p>
                <p>
                  Our attorneys have more than 20 years experience in crafting
                  and filing uncontested divorces in Alabama.
                </p>

                <h3>Contested</h3>
                <p>Can't agree?</p>
                <p>
                  Contested divorces usually involve situations where you and
                  your sponse cannot agree on some major point such as property
                  division, alimony, custody, child support, or attorney’s fees.
                  Often in these cases there are grounds for the divorce other
                  than incompatibility, such as adultery, domestic violence, or
                  drug addiction.
                </p>

                <p>
                  These cases typically take longer to complete because both
                  parties must come to a consensus.
                </p>

                {/* 
											custody, visitation, child support, alimony, and other issues. 
										<p>Save both you and your ex money and emotional stress, and future abilities to communicate, 
										which can be especially important if children are involved.</p>

											<p>When you also take into account 
											the fact that different judges have different requirements, even an uncontested divorce can become very complicated to 
											anyone who does not practice divorce law on a regular basis.</p> */}

                <h3 className="mt-4 mt-sm-0">Contempt</h3>
                <p>
                  Have you failed to meet various obligations required by a
                  court order, such as paying child support or alimony? Has your
                  ex-spouse failed to live up to the agreement?
                </p>

                <p>
                  If you are facing a contempt of court action, also called a
                  Rule Nisi, it is important that you take the case very
                  seriously. Failure to properly respond can lead to more
                  serious consequences, including garnishment of wages and tax
                  refunds, and even incarceration.
                </p>

                <p>
                  Likewise, if your ex-spouse is not abiding by the court’s
                  order to pay child support, alimony, or perform some other
                  duty mandated by the court order, your only relief may be to
                  invoke the contempt powers of the court in order to force
                  compliance.
                </p>

                <h2 className="mt-4 mt-sm-0">Child Support</h2>
                <p>
                  Child support can arise in both family court and divorce court
                  situations. Child support is money that one spouse pays each
                  month to support his or her child or children. The amount of
                  child support is easily determined and is based on the gross
                  income of each parent.
                </p>

                <p>
                  If you are facing a situation in which you are behind in child
                  support payments, we can also assist you in those proceedings.
                  By evaluating your income and that of the other parent, we may
                  be able to lower the child support payments and set you up on
                  a payment plan to pay the past due amount as well.
                  Additionally, a chapter 13 bankruptcy will allow you to pay
                  back the past due child support (child support arrearage) over
                  a period of up to 5 years.
                </p>

                <Row className="mt-5">
                  <Col>
                    <h2>FAQ</h2>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            What is the difference between an Uncontested and
                            Contested Divorce?
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p>
                              An uncontested divorce is usually based upon
                              incompatibility and the parties have reached an
                              overall consensus of how their property should be
                              separated and how to handle custody, visitation,
                              child support, alimony, and other issues. If both
                              parties agree on all issues, it can save the
                              parties money, emotional stress, and future
                              abilities to communicate, which can be especially
                              important if children are involved. If the parties
                              disagree on any one issue, the divorce is
                              contested.
                            </p>
                            <p>
                              Contested divorces traditionally involve
                              situations where the parties cannot agree on some
                              major point such as property division, alimony,
                              custody, child support, or attorney’s fees. Often
                              in these cases there are grounds for the divorce
                              other than incompatibility, such as adultery,
                              domestic violence, or drug addiction.
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                          >
                            What is a Modification?
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            Things happen in life. Perhaps your ex-spouse is
                            paying you alimony or child support and has had a
                            substantial increase in income since the divorce.
                            Perhaps your spouse has custody of your children and
                            has gone down a path in life that is not in the best
                            interest of your children. Maybe you were ordered to
                            pay child support and/or alimony and have lost your
                            job for reasons beyond your control. There are many
                            scenarios which would justify a modification of your
                            divorce decree, but you must understand that until
                            the divorce order is modified, the original order
                            stands, and all parties must comply with that order
                            whether they are able to or not. If a party fails to
                            abide by the court’s order, he or she could be held
                            in contempt of court, be ordered to pay attorney
                            fees and court costs, and even incarcerated.
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                          >
                            How Long Will Divorce Take?
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            The short answer is that it depends upon the
                            willingness of both parties to sit down and
                            negotiate a settlement in their case. It takes both
                            parties to settle the divorce and have it move as
                            expeditiously as possible. It only takes one person
                            to drive up the costs and length of time it takes to
                            get a divorce.
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
        <PracticeAreaSideBar />
      </Row>
    </Container>

    <Footer />
  </Layout>
)

export default FamilyPage

export const query = graphql`
  query {
    cover_xs: file(relativePath: { eq: "family_cover_xs.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover_sm: file(relativePath: { eq: "family_cover_sm.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover_md: file(relativePath: { eq: "family_cover_md.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover_lg: file(relativePath: { eq: "family_cover_lg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover_xl: file(relativePath: { eq: "family_cover_xl.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(width: 2400, height: 350, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
