import "./header.scss"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faDirections,
  faUserTie,
  faGavel,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"

const Header = ({ siteTitle, fluid }) => (
  <Navbar
    bg="light"
    expand="lg"
    variant="light"
    fixed="top"
    className="shadow-sm py-3"
  >
    <Container fluid={fluid}>
      <Navbar.Brand className="d-none d-sm-inline-block" as={Link} to="/">
        RWK
      </Navbar.Brand>
      <Navbar.Brand className="d-sm-none" as={Link} to="/">
        RWK
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mt-3 mt-lg-0">
          <Nav.Link as={Link} to="/" activeClassName="active">
            <FontAwesomeIcon icon={faGavel} className="d-lg-none mb-3" />{" "}
            <span>Home</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/practice-areas"
            activeClassName="active"
            partiallyActive={true}
          >
            <FontAwesomeIcon icon={faGavel} className="d-lg-none mb-3" />{" "}
            <span>Practice Areas</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/attorneys"
            activeClassName="active"
            partiallyActive={true}
          >
            <FontAwesomeIcon icon={faUserTie} className="d-lg-none mb-3" />{" "}
            <span>Attorneys</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/about"
            activeClassName="active"
            partiallyActive={true}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="d-lg-none mb-3" />{" "}
            <span>About</span>
          </Nav.Link>

          {/* <Nav.Link
            href="tel://12058332589"
            className=""
            as={Button}
            variant="primary"
          >
            205-833-2589
          </Nav.Link> */}
          <Button className="ml-3" as={Link} to="/schedule-appointment">
            Schedule Appointment
          </Button>
        </Nav>
        <Nav className="ml-auto d-lg-none mb-5">
          <Nav.Link
            href="https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C."
            target="_blank"
            rel="noopener"
            className="d-flex flex-column align-items-center justify-content-center my-2 my-lg-0 py-3 py-lg-0 border rounded w-100"
          >
            <FontAwesomeIcon icon={faDirections} className="mb-3" />{" "}
            <span>Get Directions</span>
          </Nav.Link>
          <Nav.Link
            href="tel://12058332589"
            className="d-flex flex-column align-items-center justify-content-center my-2 my-lg-0 py-3 py-lg-0 border rounded w-100"
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="mb-3" />{" "}
            <span>Call Now</span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  bg: `light`,
}

export default Header
