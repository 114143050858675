import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import { navigate } from "@reach/router"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faShieldAlt } from "@fortawesome/free-solid-svg-icons"

function ContactForm({ type, name, email, message, phone }) {
  const [validated, setValidated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errored, setErrored] = useState(false)
  const [values, setValues] = useState()

  const handleInputChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      // submit
      console.log(JSON.stringify(values))
      fetch("https://api.rwkattorneys.com", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(res => res.json())
        .then(
          result => {
            // console.log(JSON.stringify(result));
            setSubmitted(true)
            navigate("/thank-you")
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            console.error(JSON.stringify(error))
            setErrored(true)
            // 			this.setState({
            // 				isLoaded: true,
            // 				error
            // 			});
          }
        )

      try {
        trackCustomEvent({
          // string - required - The object that was interacted with (e.g.video)
          category: "ContactRequest",
          // string - required - Type of interaction (e.g. 'play')
          action: "Click",
          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
          label: `${type} full contact form campaign`,
          // number - optional - Numeric value associated with the event. (e.g. A product ID)
          // value: 43,
        })
      } catch (e) {
        console.error(e)
      }
    }

    setValidated(true)
  }

  if (errored === true) {
    return (
      <Form.Text>
        The contact system in unavailable right now. Please call us to schedule
        your consultation.
      </Form.Text>
    )
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {name && (
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            onChange={handleInputChange}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      )}

      {email && (
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            onChange={handleInputChange}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      )}

      {phone && (
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            required
            type="text"
            name="phone"
            onChange={handleInputChange}
          />
        </Form.Group>
      )}

      {message && (
        <Form.Group controlId="userMessage">
          <Form.Label>How can we help you?</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows="3"
            onChange={handleInputChange}
          />
        </Form.Group>
      )}

      <div className="text-right mb-3">
        <Button type="submit" variant="secondary" disabled={!submitted}>
          Continue &raquo;
        </Button>
      </div>

      <div className="text-muted">
        <FontAwesomeIcon icon={faLock} />
        <FontAwesomeIcon icon={faShieldAlt} className="mx-2" />
        <small>
          SSL Verified by{" "}
          <a href="https://support.google.com/chrome/answer/95617">Google</a>.
        </small>
      </div>
    </Form>
  )
}

export default ContactForm
