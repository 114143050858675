import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Container, Card } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Footer from "../../components/footer"
import BackgroundImage from "gatsby-background-image"

const PracticeAreasPage = ({ data }) => (
  <Layout className="__practiceAreas">
    <SEO title="Home" />

    <BackgroundImage
      fixed={[
        data.cover_xs.childImageSharp.fixed,
        {
          ...data.cover_sm.childImageSharp.fixed,
          media: `(min-width: 578px)`,
        },
        {
          ...data.cover_md.childImageSharp.fixed,
          media: `(min-width: 768px)`,
        },
        {
          ...data.cover_lg.childImageSharp.fixed,
          media: `(min-width: 992px)`,
        },
        {
          ...data.cover_xl.childImageSharp.fixed,
          media: `(min-width: 1200px)`,
        },
      ]}
      style={{ height: "350px" }}
      className="w-100"
    >
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col>
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-0"
              style={{ fontWeight: 600 }}
            >
              Meeting your legal needs
            </p>
            <h1 className=" display-3">Practice Areas</h1>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <Container className="mb-5">
      <Row>
        <Col>
          <p className="lead mt-4">
            We take pride in taking care of you. When you hire our firm, you’re
            hiring the knowledge and experience of all of the attorneys in our
            firm. We work tirelessly to obtain the best possible results for
            each client. Regardless of your particular legal need, Russo, White
            &amp; Keller will provide you with effective legal assistance to get
            you moving forward in the right direction.
          </p>
        </Col>
      </Row>
      <Row className="mx-1 mt-3">
        <Col xs="12" lg="6" className="mb-2">
          <Card className="shadow-sm h-100">
            <Card.Body className="d-flex flex-column">
              <h2>Bankruptcy Law</h2>
              <p>
                Put an end to creditor harassment and regain your financial
                independence by filing for consumer debt protection.
              </p>
              <Link
                className="stretched-link text-secondary"
                to="/practice-areas/bankruptcy"
              >
                Learn more
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" lg="6" className="mb-2">
          <Card className="shadow-sm h-100">
            <Card.Body className="d-flex flex-column">
              <h2>Family Law</h2>
              <p>
                Don't leave regulations and court procedures involving your
                family to chance. Processes such as divorce and child custody
                take skill and expertise to figure out.
              </p>
              <Link
                className="stretched-link text-secondary"
                to="/practice-areas/family-law"
              >
                Learn more
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" lg="6" className="mb-2 mt-xl-4">
          <Card className="shadow-sm h-100">
            <Card.Body className="d-flex flex-column">
              <h2>Criminal Law</h2>
              <p>
                Whether you were arrested for a crime against a person, a crime
                against property, or a drug crime, we can help.
              </p>
              <Link
                className="stretched-link text-secondary"
                to="/practice-areas/criminal-defense"
              >
                Learn more
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" lg="6" className="mb-2 mt-xl-4">
          <Card className="shadow-sm h-100">
            <Card.Body className="d-flex flex-column">
              <h2>Personal Injury Law</h2>
              <p>
                We can't go back and undo an injury, but we will help you seek
                relief for injuries in the form of monetary compensation.
              </p>
              <Link
                className="stretched-link text-secondary"
                to="/practice-areas/personal-injury"
              >
                Learn more
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default PracticeAreasPage

export const query = graphql`
  query {
    cover_xs: file(relativePath: { eq: "practice_areas_xs.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_sm: file(relativePath: { eq: "practice_areas_sm.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_md: file(relativePath: { eq: "practice_areas_md.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_lg: file(relativePath: { eq: "practice_areas_lg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_xl: file(relativePath: { eq: "practiceAreasCover_xl.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(height: 350, width: 1900, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
