import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap"

const Footer = ({ children }) => (
  <footer className="shadow">
    <div className="bg-gray-200 py-5">
      <Container className="py-5">
        <Row>
          <Col xs="12" sm="4">
            <div className="font-weight-bold text-uppercase text-dark mb-3">
              Russo White &amp; Keller, PC
            </div>
            <p>
              Comprehensive bankruptcy, family, criminal, and personal injury
              legal representation for residents of Alabama.
            </p>
            <a href="tel://12058332589" className="d-none">
              (205) 833-2589
              {/* TODO */}
            </a>
            <Button block className="mb-3" href="tel://12058332589">
              Call now
            </Button>
            <Button
              block
              className="mb-3"
              href="https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C."
            >
              Get Directions
            </Button>
          </Col>

          <Col xs="12" sm={{ span: 3 }} className="mt-5 mt-sm-0 d-none">
            <h5 className="mt-3 mt-sm-0">Address</h5>
            <address className="text-center text-sm-left">
              315 Gadsden Highway
              <br />
              Suite D<br />
              Birmingham, AL 35235
              <br />
              United States
            </address>
          </Col>

          <Col xs="12" sm="2" className="mt-5 mt-sm-0 text-center">
            <h5>Practice Areas</h5>
            <ListGroup variant="flush" className="text-center">
              <ListGroup.Item className="bg-transparent">
                <Link className="text-dark" to="/practice-areas/bankruptcy">
                  Bankruptcy
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <Link
                  className="text-dark"
                  to="/practice-areas/criminal-defense"
                >
                  Criminal Defense
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <Link className="text-dark" to="/practice-areas/family-law">
                  Family Law
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <Link
                  className="text-dark"
                  to="/practice-areas/personal-injury"
                >
                  Personal Injury
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs="12" sm="3" className="mt-5 mt-sm-0 text-center">
            <h5>Resources</h5>
            <ListGroup variant="flush" className="text-center">
              <ListGroup.Item className="bg-transparent">
                <a
                  className="text-dark"
                  href="https://cdn.rwkattorneys.com/Divorce-Intake.pdf"
                >
                  Divorce Intake Form
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <a
                  className="text-dark"
                  href="https://cdn.rwkattorneys.com/Adoption-Intake-Form.pdf"
                >
                  Adoption Intake Form
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <a
                  className="text-dark"
                  href="https://cdn.rwkattorneys.com/Bankruptcy-Intake-Form.pdf"
                >
                  Bankruptcy Intake Form
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <a
                  className="text-dark"
                  href="https://cdn.rwkattorneys.com/Bankruptcy-Disclosures.pdf"
                >
                  Bankruptcy Disclosures
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs="12" sm="3" className="mt-5 mt-sm-0 text-center">
            <h5>About</h5>
            <ListGroup variant="flush" className="text-center">
              <ListGroup.Item className="bg-transparent">
                <Link className="text-dark" to="/about">
                  Mission &amp; Values
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <Link className="text-dark" to="/attorneys">
                  Attorneys
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent">
                <Link className="text-dark" to="/about/legal-disclaimer">
                  Legal Disclaimer
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col>
            &copy; 2020, Russo White &amp; Keller, PC. All rights reserved.
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
)

export default Footer
