import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import ContactForm from "./contactForm"

const PracticeAreaSideBar = ({ contactMessage }) => (
  <Col>
    <div className="bg-white shadow-sm ml-3 mt-4 p-4">
      <strong>Practice Areas</strong>
      <ul>
        <li>
          <Link to="/practice-areas/bankruptcy" activeClassName="text-primary">
            Bankruptcy Law
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/family-law" activeClassName="text-primary">
            Family Law
          </Link>
        </li>
        <li>
          <Link
            to="/practice-areas/criminal-defense"
            activeClassName="text-primary"
          >
            Criminal Law
          </Link>
        </li>
        <li>
          <Link
            to="/practice-areas/personal-injury"
            activeClassName="text-primary"
          >
            Personal Injury Law
          </Link>
        </li>
      </ul>
    </div>
    {/* <Row
                  className="align-items-center d-flex"
                  style={{ height: "100%" }}
                ></Row> */}
    <div className="bg-white shadow ml-3 my-4 py-4 px-2">
      {/* <strong>Free Case Evaluation</strong> */}
      <Col>
        <p className="lead text-center text-uppercase mb-0 font-weight-bold">
          Speak to a{" "}
          <span className="text- font-weight-normal">Specialist</span>
        </p>
        <p className="text-muted">{contactMessage}</p>
      </Col>

      <Col>
        <ContactForm name email message />
      </Col>
    </div>
  </Col>
)

PracticeAreaSideBar.propTypes = {
  contactMessage: PropTypes.string,
}

PracticeAreaSideBar.defaultProps = {
  contactMessage: `Get your free legal consultation today.`,
}

export default PracticeAreaSideBar
