import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
// import ContactUsForm from "../../components/contact-us-form"
import profilePhoto from "../../images/frank-russo.png"
import { Row, Col, Container } from "react-bootstrap"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default ({ data }) => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="Frank Russo | RWK Attorneys" defer={false}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Person",
          name: `Frank Russo`,
          jobTitle: "Attorney",
          telephone: "12058332589",
          url: "https://rwkattorneys.com/attorneys/frank-russo",
        })}
      </script>
    </Helmet>

    <BackgroundImage
      fixed={[
        data.cover_xs.childImageSharp.fixed,
        {
          ...data.cover_sm.childImageSharp.fixed,
          media: `(min-width: 578px)`,
        },
        {
          ...data.cover_md.childImageSharp.fixed,
          media: `(min-width: 768px)`,
        },
        {
          ...data.cover_lg.childImageSharp.fixed,
          media: `(min-width: 992px)`,
        },
        {
          ...data.cover_xl.childImageSharp.fixed,
          media: `(min-width: 1200px)`,
        },
      ]}
      style={{ height: "350px" }}
      className="w-100"
    >
      <Container className="h-100">
        <Row className="h-100 text-light">
          <Col xl="12" className="h-100">
            <img
              alt="Frank Russo"
              src={profilePhoto}
              className="img-fluid position-absolute"
              style={{
                bottom: 0,
                right: 0,
                background:
                  "radial-gradient(circle, rgba(255,255,255,.5) 40%, rgba(0,0,0,0) 70.3%)",
                zIndex: 10,
              }}
            />
            <div className="position-relative h-100" style={{ zIndex: 15 }}>
              <div
                className="h-100 align-items-end mb-2 d-flex"
                style={{ zIndex: 15 }}
              >
                <div>
                  <p
                    className="letter-spacing-4 text-secondary text-uppercase mb-0 bg-gray-200 px-3 py-1 text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Partner (retired)
                  </p>
                  <h1 className="display-3">Frank J. Russo</h1>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <Container className="mt-3 mb-5">
      <Row>
        <Col md="8">
          <p>
            Frank was the senior partner partner at{" "}
            <strong>Russo, White &amp; Keller, P.C.</strong> and retired from
            the practice of law in 2019. &nbsp;Frank graduated from Huntingdon
            College in Montgomery, Alabama in 1974.
          </p>
          <p>
            After graduating, Frank secured a job in Iran and began teaching
            English as a foreign language. &nbsp;In 1979, Frank attended Miles
            College School of Law, graduating Cum Laude in 1982. Prior to
            forming Russo, White &amp; Keller, P.C., Frank operated his own firm
            for approximately 15 years. &nbsp;Frank is married to Caron
            Cornelius Russo.
          </p>
        </Col>
        <Col className="shadow-sm bg-gray-300">
          contact{/* <ContactUsForm /> */}
        </Col>
      </Row>
    </Container>

    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    cover_xs: file(relativePath: { eq: "downtown1_xs.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_sm: file(relativePath: { eq: "downtown1_sm.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_md: file(relativePath: { eq: "downtown1_md.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_lg: file(relativePath: { eq: "downtown1_lg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_xl: file(relativePath: { eq: "downtown1_xl.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(height: 350, width: 1900, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
