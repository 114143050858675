import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, Button } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import CountUp from "react-countup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMoneyBillWave,
  faCalendarAlt,
  faBalanceScaleLeft,
} from "@fortawesome/free-solid-svg-icons"
import Footer from "../components/footer"
import Testimonial from "../components/testimonial"
import vonSpradlinPic from "../images/vonSpradlin.png"
import randallWilsonPic from "../images/randallWilson.png"

// import vid from "../images/railroad park.MP4"
const IndexPage = ({ data }) => (
  <Layout header={false} className="__index">
    <SEO
      title="Birmingham Attorneys"
      description="People of Alabama continue to choose us because we don't just know the law; we know our clients and how to get results."
    />

    <Header bg="primary" variant="dark" />

    <video
      id="videoBG"
      height="600"
      // poster={videoPoster}
      autoPlay
      muted
      loop
      style={{
        position: "absolute",
        top: 0,
        zIndex: -1,
        width: "100%",
        // height: "calc(100vh - 80px)",
        minHeight: "600px",
        objectFit: "cover",
        marginTop: "80px",
      }}
    >
      <source src="railroad park_loved.mp4" type="video/mp4" />
      <source src="railroad park_loved.ogg" type="video/ogg" />
    </video>
    {/* <BackgroundImage
      // className="vh-100"
      fluid={[
        data.cover.childImageSharp.fluid,
        {
          ...data.cover_md.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
        {
          ...data.cover_lg.childImageSharp.fluid,
          media: `(min-width: 992px)`,
        },
        {
          ...data.cover_xl.childImageSharp.fixed,
          media: `(min-width: 1200px)`,
        },
      ]}
      style={{
        // backgroundSize: "",
        backgroundPosition: "top",
      }}
    > */}
    <Container>
      <Row
        className="align-items-center text-light"
        style={{ height: `600px`, textShadow: `2px 2px 2px rgba(0,0,0,.2)` }}
      >
        <Col xs="12" xl="6">
          <p
            className="letter-spacing-4 text-secondary text-uppercase mb-2"
            style={{ fontWeight: 600 }}
          >
            Birmingham Legal Services
          </p>
          <span className="heading">
            <span className="display-3" style={{ fontWeight: 600 }}>
              Small firm&nbsp;
            </span>
            <br />
            <span
              className="display-3 text-uppercase"
              style={{ fontWeight: 800 }}
            >
              big results
            </span>
          </span>
          <div className="mt-3">
            <Button variant="light">Get started</Button>
          </div>

          <div className="text-center d-none">
            <div className="d-flex">
              <div className="mx-xl-4">
                <div className="casedFiled">
                  <CountUp end={2900} duration={4.65} />+
                </div>
                <small className="font-weight-bold text-uppercase">
                  Bankruptcy
                  <br /> Cases Filed
                </small>
              </div>

              <div className="mx-xl-4">
                <div className="casedFiled">
                  <CountUp end={1300} duration={3.75} />+
                </div>
                <small className="font-weight-bold text-uppercase">
                  Divorce
                  <br /> Cases Filed
                </small>
              </div>

              <div className="mx-xl-4">
                <div className="casedFiled">
                  <CountUp end={1500} duration={4.25} />+
                </div>
                <small className="font-weight-bold text-uppercase">
                  Criminal
                  <br /> Cases Filed
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    {/* </BackgroundImage> */}

    <Container className="my-5 py-5 text-center">
      <Row>
        <Col>
          <p className="text-secondary letter-spacing-2 font-weight-bold text-uppercase mb-2 mt-5 mt-sm-0">
            One-of-a-kind Law Firm
          </p>
          <h1>Talking to us is easy</h1>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs="12" sm="4" xl="4" className="py-3">
          <section className="text-dark d-flex flex-column align-items-center justify-content-center p-2 p-sm-3">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="fa-5x text-primary"
              style={{ filter: `drop-shadow(3px 3px 3px #999)` }}
            />
            <h2 className="ml-2 mt-3" style={{ fontSize: "1.5rem" }}>
              Flexible Appointments
            </h2>
            <p className="text-muted lead">
              Meet on the phone, video chat or in our office
            </p>
          </section>
        </Col>
        <Col xs="12" sm="4" xl="4" className="py-0 py-sm-3">
          <section className="text-dark d-flex flex-column align-items-center justify-content-center p-2 p-sm-3">
            <FontAwesomeIcon
              icon={faMoneyBillWave}
              className="fa-5x text-primary"
              style={{ filter: `drop-shadow(3px 3px 3px #999)` }}
            />
            <h2 className="ml-2 mt-3" style={{ fontSize: "1.5rem" }}>
              Affordable Services
            </h2>
            <p className="text-muted lead">
              Clear pricing and your initial consultation is completely free
            </p>
          </section>
        </Col>
        <Col xs="12" sm="4" xl="4" className="py-3">
          <section className="text-dark d-flex flex-column align-items-center justify-content-center p-2 p-sm-3">
            <FontAwesomeIcon
              icon={faBalanceScaleLeft}
              className="fa-5x text-primary"
              style={{ filter: `drop-shadow(3px 3px 3px #999)` }}
            />
            <h2 className="ml-2 mt-3" style={{ fontSize: "1.5rem" }}>
              Experienced Legal Team
            </h2>
            <p className="text-muted lead">
              Representing people just like you for more than 20 years
            </p>
          </section>
        </Col>
      </Row>
    </Container>

    <Container className="py-5 bg-light" fluid>
      <Container className="py-5">
        <Row>
          <Col>
            <p className="text-secondary letter-spacing-2 font-weight-bold text-uppercase mb-2">
              MEETING YOUR LEGAL NEEDS
            </p>
            <h1>Our Practice Areas</h1>
            <p className="text-muted lead">
              Regardless of your legal needs, we guide you in the right
              direction
            </p>
          </Col>
          <Col
            md="4"
            className="d-lg-flex align-items-center justify-content-end"
          >
            <div>
              <Link
                to="/practice-areas"
                className="text-muted font-weight-bold"
              >
                See all services &raquo;
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="py-5 mx-1">
          <Col xs="12" sm="6">
            <h2>Bankruptcy Law</h2>
            <p>
              Debt is hard to manage. Your situation is unique. That’s why we
              tailor a personalized strategy by figuring out what matters most
              to you.
            </p>
            <ul>
              <li>Creditor Harassment</li>
              <li>Automatic Stay Violations</li>
              <li>Child Support</li>
              <li>Debt reorganization</li>
            </ul>
            <Link to="/practice-areas/bankruptcy" className="text-secondary">
              Learn more
            </Link>
          </Col>
          <Col className="mt-5 mt-sm-0" xs="12" sm="6">
            <h2>Family Law</h2>
            <p>
              Child custody, guardianship, visitation and parental rights are
              complex issues. We will help you through it.
            </p>
            <ul>
              <li>Divorce</li>
              <li>Wills</li>
              <li>Adoption</li>
              <li>Child Custody</li>
            </ul>
            <Link to="/practice-areas/family-law" className="text-secondary">
              Learn more
            </Link>
          </Col>
          {/* <Col>
            <h2>Criminal Law</h2>
          </Col>
          <Col>
            <h2>Personal Injury Law</h2>
          </Col> */}
        </Row>
      </Container>
    </Container>

    <BackgroundImage
      fluid={[
        data.bookme_xs.childImageSharp.fluid,
        {
          ...data.bookme_sm.childImageSharp.fluid,
          media: `(min-width: 576px)`,
        },
        {
          ...data.bookme_md.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
        {
          ...data.bookme_lg.childImageSharp.fluid,
          media: `(min-width: 992px)`,
        },
        {
          ...data.bookme_xl.childImageSharp.fluid,
          media: `(min-width: 1200px)`,
        },
      ]}
    >
      <Container>
        <Row
          className="align-items-center text-light"
          style={{ height: "600px" }}
        >
          <Col>
            <h1 style={{ textShadow: `2px 2px 2px rgba(0,0,0,.2)` }}>
              Ready for your free consultation?
            </h1>
            <Button variant="light" className="shadow">
              Get started
            </Button>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.95) 100%)`,
        data.cover2.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="Testimonials"
    >
      <Container className="py-5">
        <Row className="py-5">
          <Col xl={{ span: "8", offset: 2 }} className="text-center">
            <p className="text-secondary letter-spacing-2 font-weight-bold text-uppercase mb-2">
              Testimonials
            </p>
            <h1>Our clients recommend us</h1>
            <p className="lead">
              Effective attorneys don’t just know the law; they understand you.
              We work obtain the best possible results by getting to know who
              you are first.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mb-5 mb-sm-0">
            <Testimonial
              person="Von Spradlin"
              message="A lawyer that treats you like a person and not just a pay day. It's rare, but Mr Keller pulls it off."
              from="Gardendale"
              picture={vonSpradlinPic}
            />
          </Col>
          <Col>
            <Testimonial
              person="Randall Wilson"
              message="This is a great law firm and is in a great location. I would recommend them to anyone who needs any legal help."
              picture={randallWilsonPic}
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    bookme_xs: file(relativePath: { eq: "bookme1_xs.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookme_sm: file(relativePath: { eq: "bookme1_sm.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookme_md: file(relativePath: { eq: "bookme3_md.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookme_lg: file(relativePath: { eq: "bookme3_lg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookme_xl: file(relativePath: { eq: "bookme0_xl.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover2: file(relativePath: { eq: "cover5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
