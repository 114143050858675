import React, { useState } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import {
  Container,
  ResponsiveEmbed,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap"
import Footer from "../components/footer"
import DatePicker from "react-date-picker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faShieldAlt } from "@fortawesome/free-solid-svg-icons"

export default function () {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [value, onChange] = useState(new Date())

  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 14)

  return (
    <Layout style={{ color: `teal` }}>
      <Helmet title="Schedule Your Appointment" defer={false} />

      <div style={{ height: "350px" }} className="w-100 bg-dark">
        <Container className="h-100">
          <Row className="align-items-center h-100 text-light">
            <Col xl="8">
              <h1 className="display-3">Schedule Your Appointment</h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-5">
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <p>
              Your initial consultation is completely free. We can meet with you
              on the phone, in a video call or in person to review your case.
            </p>

            <Form>
              <Row>
                <Col xs="12">
                  <p>Choose a time that works best for you</p>
                </Col>
                <Col>
                  <Form.Group controlId="formGroupAppointmentDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Text className="text-muted d-inline-block ml-2">
                      * optional
                    </Form.Text>
                    <br />
                    <DatePicker
                      className="form-control"
                      onChange={onChange}
                      value={value}
                      minDate={new Date()}
                      maxDate={maxDate}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formGroupAppointmentTime">
                    <Form.Label>Time</Form.Label>
                    <Form.Text className="text-muted d-inline-block ml-2">
                      * optional
                    </Form.Text>
                    <Form.Control as="select" defaultValue="Choose...">
                      <option>Choose...</option>

                      <option>8:00 AM</option>
                      <option>8:30 AM</option>
                      <option>9:00 AM</option>
                      <option>9:30 AM</option>
                      <option>10:00 AM</option>
                      <option>10:30 AM</option>
                      <option>11:00 AM</option>
                      <option>11:30 AM</option>
                      <option>12:00 AM</option>
                      <option>12:30 AM</option>
                      <option>1:00 PM</option>
                      <option>1:30 PM</option>
                      <option>2:00 PM</option>
                      <option>2:30 PM</option>
                      <option>3:00 PM</option>
                      <option>3:30 PM</option>
                      <option>4:00 PM</option>
                      <option>4:30 PM</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formGroupFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formGroupLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" />
              </Form.Group>

              <Form.Group controlId="formGroupDescription">
                <Form.Label>
                  Breifly describe your case or legal concerns
                </Form.Label>
                <Form.Text className="text-muted">* optional</Form.Text>
                <Form.Control as="textarea" />
              </Form.Group>

              <Button variant="secondary">Request Appointment</Button>
              <div className="text-muted d-inline-block ml-5">
                <FontAwesomeIcon icon={faLock} />
                <FontAwesomeIcon icon={faShieldAlt} className="mx-2" />
                <small>
                  SSL Verified by{" "}
                  <a href="https://support.google.com/chrome/answer/95617">
                    Google
                  </a>
                  .
                </small>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </Layout>
  )
}
