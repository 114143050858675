import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import profilePhoto from "../../images/greg-white.png"
import { Row, Col, Container, Alert, ListGroup } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrophy } from "@fortawesome/free-solid-svg-icons"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const areasOfPractice = [
  "Drug possession",
  "Domestic violence",
  "DUIs & reckless driving",
  "Federal crimes",
  "Petty crimes &amp; misdemeanors",
  "sex offenses",
  "restraining orders",
]

export default ({ data }) => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="Gregory White | RWK Attorneys" defer={false}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Person",
          name: `Gregory White`,
          jobTitle: "Attorney",
          telephone: "12058332589",
          url: "https://rwkattorneys.com/attorneys/frank-russo",
        })}
      </script>
    </Helmet>

    <BackgroundImage
      fixed={[
        data.cover_xs.childImageSharp.fixed,
        {
          ...data.cover_sm.childImageSharp.fixed,
          media: `(min-width: 578px)`,
        },
        {
          ...data.cover_md.childImageSharp.fixed,
          media: `(min-width: 768px)`,
        },
        {
          ...data.cover_lg.childImageSharp.fixed,
          media: `(min-width: 992px)`,
        },
        {
          ...data.cover_xl.childImageSharp.fixed,
          media: `(min-width: 1200px)`,
        },
      ]}
      style={{ height: "350px" }}
      className="w-100"
    >
      <Container className="h-100">
        <Row className="h-100 text-light">
          <Col xl="12" className="h-100">
            <img
              alt="Gregory White"
              src={profilePhoto}
              className="img-fluid position-absolute"
              style={{
                bottom: 0,
                right: 0,
                background:
                  "radial-gradient(circle, rgba(255,255,255,.5) 40%, rgba(0,0,0,0) 70.3%)",
                zIndex: 10,
              }}
            />
            <div className="position-relative h-100" style={{ zIndex: 15 }}>
              <div
                className="h-100 align-items-end mb-2 d-flex"
                style={{ zIndex: 15 }}
              >
                <div>
                  <p
                    className="letter-spacing-4 text-secondary text-uppercase mb-0 bg-gray-200 px-3 py-1 text-center"
                    style={{ fontWeight: 600 }}
                  >
                    Partner
                  </p>
                  <h1 className="display-3">Gregory White</h1>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <Container>
      <Row>
        <Col md="8" className="p-5 border-left border-right bg-light shadow-sm">
          <p>
            Greg is owner of Russo, White &amp; Keller, P.C.&nbsp; He joined the
            Firm in 2000.&nbsp; He currently handles cases in divorce (both
            uncontested and contested), custody disputes, child support,
            criminal defense, and personal injury.
          </p>
          <p>
            Greg was graduated from Florida State University in 1979 with a
            Bachelor of Science.&nbsp; In 1991, he received his Juris Doctor
            degree from Cumberland School of Law.
          </p>
          <p>
            Greg is licensed to practice in all courts in Alabama and in the
            United States District Court for the Northern District of
            Alabama.&nbsp; He is a member of the Alabama State Bar and the
            Birmingham Bar Association.
          </p>
          <p>
            Greg has been married to the same beautiful woman for 30 years with
            whom he has two wonderful children.&nbsp; Greg is a member and
            regular attender of Mountain Top Community Church in Vestavia Hills,
            Alabama.
          </p>

          <dl className="row">
            <dt className="col-sm-3">Education</dt>
            <dd className="col-sm-9">
              <p>Cumberland School of Law, J.D. (1991)</p>
              <p>Florida State University, B.S. (1979)</p>
            </dd>
            <dt className="col-sm-3">Bar Admissions</dt>
            <dd className="col-sm-9">
              <p>Alabama (all state courts)</p>
              <p>Eleventh Circuit Court of Appeals</p>
              <p>Northern District of Alabama</p>
              <p>Middle District of Alabama</p>
            </dd>
          </dl>
        </Col>
        <Col>
          <Alert
            variant="light"
            className="d-flex justify-content-around align-items-center shadow-sm mt-3"
          >
            <FontAwesomeIcon icon={faTrophy} className="fa-lg m-3" />
            &nbsp;
            <p className="mb-0">
              Greg is recognized as one of{" "}
              <strong>Birmingham's Top Attorneys</strong> in Divorce by
              Birmingham Magazine!
            </p>
          </Alert>

          <div className="bg-light shadow-sm border text-center mb-5 mt-3">
            <h2 className="my-3" style={{ fontSize: "1rem" }}>
              Areas of Practice
            </h2>

            <ul className="list-group"></ul>
            <ListGroup variant="flush">
              {areasOfPractice.map(areaOfPractice => (
                <ListGroup.Item>{areaOfPractice}</ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>

    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    cover_xs: file(relativePath: { eq: "downtown1_xs.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_sm: file(relativePath: { eq: "downtown1_sm.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_md: file(relativePath: { eq: "downtown1_md.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_lg: file(relativePath: { eq: "downtown1_lg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover_xl: file(relativePath: { eq: "downtown1_xl.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(height: 350, width: 1900, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
