/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"
import { Helmet } from "react-helmet"
import { dom, config } from "@fortawesome/fontawesome-svg-core"

// Disable the automatic insertion
// of CSS into the head of the document.
config.autoAddCss = false

const Layout = ({ header, children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css"
        />
        <style type="text/css">{dom.css()}</style>
      </Helmet>
      {header && <Header siteTitle={data.site.siteMetadata.title} />}

      <main className={className}>{children}</main>

      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer> */}
    </>
  )
}

Layout.defaultProps = {
  header: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
}

export default Layout
