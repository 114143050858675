import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faCalendarAlt,
  faMoneyBillWave,
  faBalanceScaleLeft,
} from "@fortawesome/free-solid-svg-icons"
import PracticeAreaSideBar from "../../../components/practiceAreaSideBar"
import Footer from "../../../components/footer"

const CriminalDefensePage = ({ data }) => (
  <Layout>
    <SEO
      title="Criminal Law | Practice Areas"
      description="Regardless of whether you were arrested for a crime against a person (like assault and battery, rape, or murder), a crime against property (like shoplifting, burglary, or arson), or a drug crime (marijuana possession or cocaine dealing), we can help."
    />

    <div className="bg-dark" style={{ height: "350px" }}>
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col>
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-2"
              style={{ fontWeight: 600 }}
            >
              1500+ Cases Filed
            </p>
            <h1 className=" display-3">Criminal Law</h1>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row>
        <Col
          xl="8"
          className="py-3 border-left border-right bg-light shadow-sm"
        >
          <Container>
            <Row>
              <Col>
                <h1 className="mt-4">Affordable Legal Defense</h1>
                <p className="lead">Being accused of a crime is truamatic.</p>
                <p>
                  Our attorneys are experienced in trial and represent
                  individuals in a wide spectrum of legal matters from capitol
                  murder charges to those charged with misdemeanor crimes such
                  as shoplifting. We have had great success in obtaining
                  acquittals, reduced charges, and getting charges dropped
                  completely. We represent individuals in various charges in
                  municipal courts such as:
                </p>
                <Row>
                  <Col>
                    <Row className="my-4 text-center">
                      {/* todo: add d-flex, center items, set border color, add more offenses */}
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Drunk Driving (DUI/DWI)</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Driving Offenses</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Probation</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>assault</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Drug Crimes</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Sex Crimes</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>trafficking</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Violent Crimes</div>
                      </Col>
                      <Col xs="4" className="p-3">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-secondary fa-lg"
                        />
                        <div>Domestic Violence</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container fluid className="text-center bg-dark text-light py-5">
            <Container>
              <Row>
                <Col xs="12" sm="4" xl="4" className="py-3">
                  <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3 p-xl-4">
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-2x" />
                    <div className="ml-2">Flexible Appointments</div>
                  </section>
                </Col>
                <Col xs="12" sm="4" xl="4" className="py-0 py-sm-3">
                  <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3 p-xl-4">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="fa-2x" />
                    <div className="ml-2">Affordable Services</div>
                  </section>
                </Col>
                <Col xs="12" sm="4" xl="4" className="py-3">
                  <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3 p-xl-4">
                    <FontAwesomeIcon
                      icon={faBalanceScaleLeft}
                      className="fa-2x"
                    />
                    <div className="ml-2">Experienced Legal Team</div>
                  </section>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container className="mt-5">
            <Row>
              <Col>
                <p>
                  Don't let a criminal charge ruin your life. Regardless of
                  whether you were arrested for a crime against a person (like
                  assault and battery, rape, or murder), a crime against
                  property (like shoplifting, burglary, or arson), or a drug
                  crime (marijuana possession or cocaine dealing), we can help.
                </p>

                <h2>Experienced Criminal Defense Representation</h2>
                <p className="lead">
                  You need an experienced criminal defense attorney to protect
                  your rights and freedom when you have been charged with a
                  serious criminal offense.
                </p>
                <p>
                  When you have been charged with a criminal offense, your
                  future is at stake. You need an experienced criminal defense
                  attorney who will treat your case with compassion and
                  dedication to protect your rights and freedom. You'll get that
                  here at Russo, White &amp; Keller, P.C. For over 20 years, we
                  have been a passionate defender of clients charged with a
                  variety of criminal crimes. Criminal cases are often highly
                  technical in nature. To avoid a conviction, you need an
                  attorney that is deeply knowledgeable in criminal trials and
                  has the courtroom experience to mount a convincing defense for
                  you.
                </p>
                <p>
                  Start with a free evaluation, you have nothing to lose and
                  everything to gain.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
        <PracticeAreaSideBar contactMessage="Get your case reviewed by us today." />
      </Row>
    </Container>

    <Footer />
  </Layout>
)

export default CriminalDefensePage
