import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Col, Container, Button, Accordion, Card } from "react-bootstrap"
import Footer from "../../../components/footer"
import PracticeAreaSideBar from "../../../components/practiceAreaSideBar"

const BankruptcyPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <div className="bg-dark" style={{ height: "350px" }}>
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col>
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-2"
              style={{ fontWeight: 600 }}
            >
              2900+ Cases Filed
            </p>
            <h1 className=" display-3">Bankruptcy Law</h1>
          </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <Row>
        <Col
          xl="8"
          className="py-3 border-left border-right bg-light shadow-sm"
        >
          <h1>Making Bankruptcy Affordable</h1>
          <p>
            It is difficult to deal with debt when you are faced financial
            hardship. Fortunately for many debtors, bankruptcy can provide a way
            out. Russo, White &amp; Keller is a full-service law firm providing
            the Birmingham area with a complete range of bankruptcy services.
            These services are designed to give you a financial fresh start by
            eliminating most debts in one fell swoop.
          </p>
          <p>
            If you need help, contact us immediately for a free consultation. We
            understand that no matter how similar two cases may appear, no two
            cases are ever identical in all respects. Our attention to detail
            allows us to create winning strategies that are tailor-made to suit
            the needs of each client.
          </p>
          <p>
            We take pride in developing mutually beneficial relationships with
            our clients by ensuring that each client understands every aspect of
            his or her case or legal issue.
          </p>

          <h2>Take the guesswork out of your finances</h2>
          <p className="lead">
            If you are wrestling with overwhelming debt in the Birmingham area –
            and the debt is winning – we can help.
          </p>

          <h3>Which Filing Is Best?</h3>
          <p>
            The US bankruptcy code can be difficult to make sense of by yourself
            but you have many options to relieve yourself from debt obligations.
            We consider and share all options available to you so you can make
            sure we choose the right filing for you. There are some basic
            qualifications you must meet and certain situations may dicit which
            filing you should make. Take the guess work out of your filing by
            allowing us to evaluate your situation and help guide you through
            every step along the way. We will make all of the filings with the
            correct court system on your behalf.
          </p>
          <h4>Chapter 7</h4>
          <p>
            The most common type of personal bankruptcy is a Chapter 7
            bankruptcy filing. A Chapter 7 will discharge credit card debt,
            medical debts, auto loans, mortgage debt, and sometimes more. Since
            Chapter 7 bankruptcy is designed for average-income and low-income
            persons, you must pass a means test to qualify. The test compares
            your income against the median household income in Alabama. Almost
            anyone whose income is below the median will be eligible for Chapter
            7 bankruptcy, but if your income is higher, you may still qualify
            under certain conditions.
          </p>
          <p>
            Bankruptcy isn’t for everyone who is struggling with debt, but for
            some, a Chapter 7 bankruptcy is the only realistic option. We can
            evaluate your financial circumstances and offer you practical advice
            regarding the means test, bankruptcy, and other legal options
            available.
          </p>
          <p>
            We represent a wide range of businesses in bankruptcy proceedings,
            including sole proprietorships, limited liability companies (LLCs)
            and partnerships. We understand how unique your debt problems are,
            and our attorneys will work with you to craft a strategy that match
            the unique needs of your business. We will help you explore debt
            relief options that are available to you, including:
          </p>
          <h4>Chapter 13</h4>
          <p>
            Another popular option is a Chatper 13 filing because it allows
            debtors to create and follow a realistic debt payment plan. When you
            file for bankruptcy under either Chapter 13 or under Chapter 7, an
            order for relief – an “automatic stay” – goes into effect. The
            automatic stay prevents creditors from harassing you about mortgage
            debt, credit card debt, auto loan debt, and medical bills during the
            period of the bankruptcy. A Chapter 13 bankruptcy usually allows
            those with a regular income to pay their debts over a period of
            three-to-five years, and it consolidates those debts into one
            monthly payment.
          </p>
          <p>
            It’s the legal way to avoid foreclosure or repossession and to begin
            managing out-of-control debt effectively. A number of factors
            determine if you are eligible to file a Chapter 13 bankruptcy. If
            you believe that a Chapter 13 bankruptcy might be best for you,
            speak right away with an experienced Chapter 13 bankruptcy lawyer at
            the Russo, White &amp; Keller Law Firm.
          </p>
          <h4>Chapter 11</h4>
          <p>
            Do you own a small business? You worked hard to grow your business,
            but hard times have led to significant debt. If Chapter 13 looked
            appealing to you because you would like to reorganize your business
            debts to make it more managable then a Chapter 11 might be right for
            you. Chapter 11 is similiar to Chapter 13 applies to all, not just
            individuals. If you decide filing for small business bankruptcy is
            right for your company, we will provide you with skilled legal
            representation throughout the difficult process. Call us or contact
            us online for a free consultation with an Alabama bankruptcy
            attorney. It could be your first step on the road to debt relief.
          </p>
          <p>
            Start with a free evaluation, you have nothing to lose and
            everything to gain.
          </p>

          <Row className="mt-5">
            <Col>
              <h2>FAQ</h2>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      What is a discharge?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      A discharge is a court order which prohibits the
                      collection of all debt which you listed in your bankruptcy
                      petition. The discharge follows and protects you the rest
                      of your life. There are certain debts that are excepted
                      from discharge and that you still must pay despite a
                      discharge in your bankruptcy case. Three of the most
                      common debts that you still must pay after a discharge are
                      federal and state income taxes, student loans, and child
                      support and alimony. Another type of debt which you must
                      repay despite receiving a discharge is debt that you have
                      reaffirmed, such as a house or car.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Will I be able to keep my house or car and other property?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      In general, the answer is yes. Chapter 7 bankruptcy is
                      designed to wipe out your debt. However, this is
                      conditioned upon you not owning any property that is not
                      exempt. Non-exempt property is sold by the bankruptcy
                      court, converted to cash, and then used to pay back all or
                      a portion of the debt that is owed by you. The bankruptcy
                      law allows you to exempt your property, whether it be a
                      home, car, 401(k), or other property. If your property is
                      exempt, it generally cannot be used to pay your creditors
                      any money. In most cases, all of your property will be
                      exempt. The exemptions differ for each piece of property
                      that you own, and we will be able to explain to you in
                      more detail about these exemptions.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      Will I be able to get rid of property I no longer want or
                      can afford?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      In general, the answer is yes. Some debtors can no longer
                      afford their house or car payments. In other cases, the
                      house is just too big for them to keep up and they want to
                      downsize. In still other cases, a car may keep breaking
                      down and it is not worthwhile to continue to repair.
                      Regardless of the circumstances, you can still surrender
                      the property back to the mortgage company or to the
                      lienholder. If you do so, the balance of the debt will be
                      wiped out (discharged).
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      What if there is a co-signer on a debt of mine?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      If there is a co-signer on a credit card, medical bill,
                      personal loan, or other such bill, then the co-signer will
                      be responsible for paying that bill once you have
                      discharged your debt. This is also the case if there is a
                      co-signer on a home mortgage or car note and you are
                      surrendering the home or car. If, on the other hand, you
                      are keeping the home or car, and you reaffirm the debt,
                      then both you and the co-signer will remain obligated to
                      pay the debt reaffirmed.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Col>

        <PracticeAreaSideBar />
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default BankruptcyPage
