import React from "react"
import "../../components/layout.scss"
import "./landing.scss"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Container, Row, Col, Navbar, ListGroup } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import SimpleContactForm from "../../components/simpleContactForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarAlt,
  faMoneyBillWave,
  faBalanceScaleLeft,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import RequestCaseEvaluationCTA from "../../components/requestCaseEvaluationCTA"
import { dom, config } from "@fortawesome/fontawesome-svg-core"
import videoPoster from "../../images/family-law-cover.png"

// Disable the automatic insertion
// of CSS into the head of the document.
config.autoAddCss = false

export default ({ data, location }) => (
  <section>
    {/* {JSON.stringify(location)} */}
    <Helmet title="Divorce - Russo, White &amp; Keller, P.C." defer={false}>
      <style type="text/css">{dom.css()}</style>
    </Helmet>
    <video
      id="videoBG"
      poster={videoPoster}
      autoPlay
      muted
      loop
      style={{
        position: "absolute",
        top: 0,
        zIndex: -1,
        width: "100%",
        height: "calc(100vh - 80px)",
        minHeight: "600px",
        objectFit: "cover",
      }}
    >
      <source
        src="https://cdn.rwkattorneys.com/divorce-cover.mp4"
        type="video/mp4"
      />
    </video>
    {/* <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(0,0,0,.85) 0%, rgba(0,0,0,.85) 100%)`,
        // data.cover.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    > */}
    <Navbar
      bg="light"
      expand="lg"
      className="shadow-sm text-dark"
      style={{ minHeight: "10vh" }}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="mx-auto mx-sm-0">
          RWK
        </Navbar.Brand>

        <section className="mx-auto mr-xl-0 ml-xl-auto text-center pt-2 position-relative">
          <h3>
            <a href="tel://+12058332589" className="stretched-link">
              (205) 833-2589
            </a>
          </h3>
          <p className="text-uppercase mb-0 font-weight-bold">
            Free Case Evaluation
          </p>
        </section>
      </Container>
    </Navbar>
    <Container fluid>
      <Row className="text-center text-uppercase font-weight-bolder">
        <Col
          className="px-0 px-3 py-2 bg-secondary text-light"
          style={{ letterSpacing: "4px" }}
        >
          Our team will guide you through the legal process without costing you
          a fortune.
        </Col>
      </Row>
    </Container>
    <Container
      fluid
      style={{
        background:
          "linear-gradient(0deg, rgba(0,0,0,.85) 0%, rgba(0,0,0,.85) 100%)",
      }}
    >
      <Container>
        <Row className="text-light align-items-center align-items-md-start justify-content-center">
          <Col xs="12">
            <h1 className="text-light text-center pt-4 pb-2 pb-lg-4">
              Affordable Divorce Attorney
              <br /> Serving Birmingham
            </h1>
          </Col>

          <Col
            className="h-100"
            sm={{ span: 10, offset: 1 }}
            md={{ span: 6, offset: 0 }}
            xl="6"
          >
            <h5 className="text-secondary">
              Respectful. Problem solvers. Discrete.
            </h5>
            <p className="lead">
              Our talented team of attorneys will guide you through a divorce
              without the drama, without costing you a fortune, and without
              ruining your family.
            </p>

            <ListGroup variant="flush" className="pb-3">
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-secondary fa-lg"
                />{" "}
                <span className="ml-4">
                  We will provide clarity into your divorce matter so that you
                  can make an informed decision.
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-secondary fa-lg"
                />{" "}
                <span className="ml-4">
                  We recommend strategies specific to your circumstances.
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-secondary fa-lg"
                />{" "}
                <span className="ml-4">
                  Our commitment is to help you to fully resolve your legal
                  issues and to move forward to a new future.
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col
            xs="12"
            md="6"
            xl="5"
            className="px-5 py-5"
            style={{
              background:
                "linear-gradient(180deg, rgba(52,58,64,1) 0%, rgba(52,58,64,.2) 100%)",
            }}
          >
            <h4 className="text-light">Request Case Evaluation</h4>
            <p>
              Get your case reviewed by an experienced attorney. Get started
              now.
            </p>
            <SimpleContactForm type="divorce" />
          </Col>
        </Row>
      </Container>
      {/* </BackgroundImage> */}
    </Container>
    <Container fluid className="text-center bg-secondary text-light">
      <Container>
        <Row>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="fa-2x text-light"
              />
              <div className="ml-2">Flexible Appointments</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-0 py-sm-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faMoneyBillWave} className="fa-2x" />
              <div className="ml-2">Affordable Services</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faBalanceScaleLeft} className="fa-2x" />
              <div className="ml-2">Experienced Legal Team</div>
            </section>
          </Col>
        </Row>
      </Container>
    </Container>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.9) 100%)`,
        data.cover2.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    >
      <Container className="text-center text-dark py-xl-5">
        <Row
          className="align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <Col xl="8" className="text-center mb-5">
            <h2 className="mt-5 mb-3 mt-md-3">
              A Successful Divorce Starts with
              <br className="d-none d-sm-block" /> Finding the Right Lawyer
            </h2>

            <p>
              Divorce cases can go horribly wrong when attorneys apply the wrong
              technicues in the case. We specialize in divorce and family Law,
              know the legal roadblocks and will guide you through the daunting
              divorce process with a strategy personalized to your situation.
              With our guidance, you can make informed decisions and get your
              life back on track.
            </p>
            <p>
              We help our clients address their legal issues while considering
              the other factors important to them - keeping conflict away from
              children, keeping resources within the family, and keeping
              everyone out of court.
            </p>

            <p>Get your case reviewed by one of our experienced attorneys.</p>

            <RequestCaseEvaluationCTA type="divorce" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  </section>
)
export const query = graphql`
  query {
    cover: file(relativePath: { eq: "family-law-cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover2: file(relativePath: { eq: "landing/cover5.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
