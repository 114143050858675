import PropTypes from "prop-types"
import React from "react"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

const Testimonial = ({ person, from, message, picture }) => (
  <Card className="h-100 shadow mx-5">
    <Card.Body className="d-flex flex-column justify-content-center m-5">
      <FontAwesomeIcon icon={faQuoteRight} className="fa-lg text-secondary" />
      <div
        className="position-absolute"
        style={{
          top: "50%",
          left: 0,
          transform: "translate(-50%, -50%)",
          WebkitTransform: "translate(-50%,-50%)",
        }}
      >
        <img alt={person} src={picture} />
      </div>
      <blockquote className="blockquote mt-3">
        <p className="mb-0">{message}</p>
        <footer className="mt-3">
          <div className="d-inline-block">
            <span className="font-weight-bold text-dark">{person}</span>
            {from && (
              <span>
                <br /> from {from}
              </span>
            )}
            <br />
            <div className="">
              <FontAwesomeIcon icon={faStar} className="text-warning" />
              <FontAwesomeIcon icon={faStar} className="text-warning" />
              <FontAwesomeIcon icon={faStar} className="text-warning" />
              <FontAwesomeIcon icon={faStar} className="text-warning" />
              <FontAwesomeIcon icon={faStar} className="text-warning" />
            </div>
          </div>
        </footer>
      </blockquote>
    </Card.Body>
  </Card>
)

Testimonial.propTypes = {
  person: PropTypes.string,
  from: PropTypes.string,
  message: PropTypes.string,
}

Testimonial.defaultProps = {}

export default Testimonial
