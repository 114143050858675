import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import frankProfilePhoto from "../../images/frank-russo.png"
import gregProfilePhoto from "../../images/greg-white.png"
import robbProfilePhoto from "../../images/robert-keller.png"
import Attorney from "../../components/attorney"
import Footer from "../../components/footer"

export default () => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="Attorneys | RWK Attorneys" defer={false} />

    <div style={{ height: "350px" }} className="w-100 bg-dark">
      <Container className="h-100">
        <Row className="align-items-center h-100 text-light">
          <Col xl="8">
            <p
              className="letter-spacing-4 text-secondary text-uppercase mb-0"
              style={{ fontWeight: 600 }}
            >
              Meet our Attorneys
            </p>
            <h1 className="display-3">Meet our Attorneys</h1>
          </Col>
        </Row>
      </Container>
    </div>

    <div className="bg-light py-5">
      <Container className="mb-5">
        <Row>
          <Col>
            {/* <p className="text-muted lead">
              We work hard to protect your rights. We have over 75 years of
              combined legal expierence in getting results for people just like
              you.
            </p> */}
            <p className="letter-spacing-2 text-center text-secondary text-uppercase mb-0 py-5 font-weight-bolder">
              Our founders
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={{ offset: 1, span: 10 }}>
            <Row>
              <Col xs={12} sm={4}>
                <Attorney name="Frank Russo" photo={frankProfilePhoto} />
              </Col>
              <Col xs={12} sm={4}>
                <Attorney name="Gregory White" photo={gregProfilePhoto} />
              </Col>
              <Col xs={12} sm={4}>
                <Attorney name="Robert Keller" photo={robbProfilePhoto} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer />
  </Layout>
)
