// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-js": () => import("./../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-legal-disclaimer-js": () => import("./../../src/pages/about/legal-disclaimer.js" /* webpackChunkName: "component---src-pages-about-legal-disclaimer-js" */),
  "component---src-pages-attorneys-frank-russo-js": () => import("./../../src/pages/attorneys/frank-russo.js" /* webpackChunkName: "component---src-pages-attorneys-frank-russo-js" */),
  "component---src-pages-attorneys-gregory-white-js": () => import("./../../src/pages/attorneys/gregory-white.js" /* webpackChunkName: "component---src-pages-attorneys-gregory-white-js" */),
  "component---src-pages-attorneys-index-js": () => import("./../../src/pages/attorneys/index.js" /* webpackChunkName: "component---src-pages-attorneys-index-js" */),
  "component---src-pages-attorneys-robert-keller-js": () => import("./../../src/pages/attorneys/robert-keller.js" /* webpackChunkName: "component---src-pages-attorneys-robert-keller-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-bankruptcy-js": () => import("./../../src/pages/landing/bankruptcy.js" /* webpackChunkName: "component---src-pages-landing-bankruptcy-js" */),
  "component---src-pages-landing-criminal-defense-js": () => import("./../../src/pages/landing/criminal-defense.js" /* webpackChunkName: "component---src-pages-landing-criminal-defense-js" */),
  "component---src-pages-landing-divorce-js": () => import("./../../src/pages/landing/divorce.js" /* webpackChunkName: "component---src-pages-landing-divorce-js" */),
  "component---src-pages-practice-areas-bankruptcy-index-js": () => import("./../../src/pages/practice-areas/bankruptcy/index.js" /* webpackChunkName: "component---src-pages-practice-areas-bankruptcy-index-js" */),
  "component---src-pages-practice-areas-criminal-defense-index-js": () => import("./../../src/pages/practice-areas/criminal-defense/index.js" /* webpackChunkName: "component---src-pages-practice-areas-criminal-defense-index-js" */),
  "component---src-pages-practice-areas-family-law-index-js": () => import("./../../src/pages/practice-areas/family-law/index.js" /* webpackChunkName: "component---src-pages-practice-areas-family-law-index-js" */),
  "component---src-pages-practice-areas-index-js": () => import("./../../src/pages/practice-areas/index.js" /* webpackChunkName: "component---src-pages-practice-areas-index-js" */),
  "component---src-pages-practice-areas-personal-injury-index-js": () => import("./../../src/pages/practice-areas/personal-injury/index.js" /* webpackChunkName: "component---src-pages-practice-areas-personal-injury-index-js" */),
  "component---src-pages-schedule-appointment-js": () => import("./../../src/pages/schedule-appointment.js" /* webpackChunkName: "component---src-pages-schedule-appointment-js" */),
  "component---src-pages-thank-you-js": () => import("./../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

